html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: arial;
  font-size: 14px;
  background: #f2f2f2;
}

/* --------------------------------------------------- */

* {
  scrollbar-width: thin;
  scrollbar-color: darkgrey transparent;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track-piece  {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: darkgrey;
}

*::-webkit-scrollbar-thumb:horizontal {
  height: 10px;
  background-color: darkgrey;
}

/* --------------------------------------------------- */

.page-header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 33px;
}

/* --------------------------------------------------- */

.pageFooter {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 16px;
}

/* --------------------------------------------------- */

.panelTop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
}

.panelTop .insert {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 9px;
}

.panelBottom {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
}

/* --------------------------------------------------- */

.page-content {
  position: fixed;
  top: 33px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  transition: left 0.1s linear;
}

.content {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  overflow-y: auto;
}

.content-column {
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  display: flex;
  flex-direction: column;
}

.scrollable {
  overflow: auto;
}

/* --------------------------------------------------- */

.withFlex {
  display: flex;
}

/* --------------------------------------------------- */

h1 {
  margin: 18px;
  padding :0;
  font-size: 24px;
  display: flex;
  align-items: center;
}

h1 .icon {
  float: left;
  margin-right: 9px;
  width: 28px;
  height: 28px;
  background-size: 28px 28px;
  background-color: #ffa55e;
  border-radius: 24px;
  padding: 3px;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

h1.disabled {
  color: #7b7b7b;
}

h1.disabled .icon {
  background-color: #838383;
}

/* --------------------------------------------------- */

h2 {
  margin: 6px;
  padding :0;
  font-size: 24px;
  font-weight: bold;
}

h2 .icon {
  float: left;
  width: 27px;
  height: 27px;
  background-size: 27px 27px;
  margin-right: 6px;
}

.sectionBanner {
  margin: 9px 18px;
  background: #dedede;
  border-radius: 9px;
  padding: 9px;
  cursor: pointer;
  transition: background 0.1s linear;
}

.sectionBanner:hover {
  background: #7be195;
}

/* --------------------------------------------------- */

.loadingPlaceholder, .errorPlaceholder {
  margin: 10px 18px;
  overflow: auto;
}

.errorPlaceholder {
  background: #fb8b8b;
  padding: 6px;
  border-radius: 9px;
}

.loadingPlaceholder .icon, .errorPlaceholder .icon {
  width: 42px;
  height: 42px;
  background-size: 42px 42px;
  float: left;
}

.loadingPlaceholder span, .errorPlaceholder span {
  float: left;
  font-size: 18px;
  margin: 9px;
}

.errorPlaceholder .button {
  display: flex;
  align-items: center;
}

.errorPlaceholder .button span {
  font-size: 14px;
}

/* --------------------------------------------------- */

.button {
  background: #005aa0;
  padding: 6px;
  border-radius: 6px;
  border: 0px;
  cursor: pointer;
  color: white;
  transition: background 0.1s linear;
}

.button:hover {
  background: #13a538;
}

.button.disabled {
  cursor: initial;
  background: #005aa0;
}

.button.error {
  background: #f20808c9;
}

.button.left {
  float: left;
  margin-right: 3px;
}

.button.right {
  float: right;
  margin-left: 3px;
}

.button .icon {
  float: left;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.button span {
  margin: 0 4px 0 4px;
  float: left;
}

/* --------------------------------------------------- */

.ant-form .ant-form-item-label {
  white-space: normal;
}

.ant-custom-range-picker .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-custom-range-picker .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

/* .ant-custom-range-picker .ant-picker-panels > *:last-child {
  display: none !important;
} */

.ant-custom-range-picker .ant-picker-panel-container, 
.ant-custom-range-picker .ant-picker-footer {
  width: 280px !important;
}

.ant-custom-range-picker .ant-picker-footer-extra > div {
  flex-wrap: wrap !important; 
} 