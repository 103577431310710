/* Calendar */

.shift-planner .calendar {
	display: block;
	position: relative;
	width: 100%;
	background: var(--neutral-color);
	padding: 0px 18px;
}

.shift-planner .calendar .body {
	border: 1px solid var(--border-color);
}
  
.shift-planner .calendar .header.row {
	text-transform: uppercase;
	font-weight: 700;
	font-size: 200%;
	padding: 1.5em 0;
	border-bottom: 1px solid var(--border-color);
}

.shift-planner .calendar .days.row {
	font-weight: 400;
	border-bottom: 1px solid var(--border-color);
	background: var(--dark-main-color);
	color: var(--neutral-color);
	display: flex;
	align-items: center;
}

.shift-planner .calendar .body .cell {
	position: relative;
	min-height: 9em;
	border-right: 1px solid var(--border-color);
	overflow: hidden;
	cursor: pointer;
	background: var(--neutral-color);
	transition: 0.25s ease-out;
}

.shift-planner .calendar .body .mobile-row.cell {
	border: 1px solid;
	border-bottom: none;
	display: flex;
}

.shift-planner .calendar .body .mobile-row.cell .info {
	flex: 1 0;
	display: flex;
	flex-direction: column;
}

.shift-planner .calendar .body .mobile-row.cell .info .number {
	align-self: flex-end;
	position: inherit;
	line-height: 1.5715;
}

.shift-planner .calendar .body .mobile-row.cell .button-container {
	display: flex;
	min-width: 7%;
	flex: none;
	background-color: var(--remove-color);
}

.shift-planner .calendar .body .mobile-row.cell .button-container .button-clear {
	align-self: center;
	text-transform: uppercase;
	color: var(--neutral-color);
	letter-spacing: 2px;
	writing-mode: vertical-rl;
	width: fit-content;
}

.shift-planner .calendar .body .mobile-row.cell:last-of-type {
	border: 1px solid;
}

.shift-planner .calendar .body .cell:hover {
	background: var(--bg-color);
	transition: 0.5s ease-out;
}

.shift-planner .calendar .body .selected {
	border-left: 10px solid transparent;
	border-image: linear-gradient(45deg, #1a8fff 0%,#53cbf1 40%);
	border-image-slice: 1;
}

.shift-planner .calendar .body .row {
	border-bottom: 1px solid var(--border-color);
}

.shift-planner .calendar .body .row:last-child {
	border-bottom: none;
}

.shift-planner .calendar .body .cell:last-child {
	border-right: none;
}

.shift-planner .calendar .body .cell .number {
	position: absolute;
	font-size: 82.5%;
	line-height: 1;
	top: .75em;
	right: .75em;
	font-weight: 700;
}

.shift-planner .calendar .body .col.disabled {
	color: var(--text-color);
	background-color: var(--disabled-color);
	pointer-events: none;
}

.shift-planner .calendar .body .col.holiday,
.shift-planner .calendar .body .col.weekend {
  background-color: var(--holiday-color);
}

.shift-planner .calendar .body .col.faded {
	opacity: 0.5;
}

.shift-planner .calendar .body .mobile-row.disabled {
	color: var(--text-color);
	background-color: var(--disabled-color);
	pointer-events: none;
}

.shift-planner .calendar .body .mobile-row.holiday,
.shift-planner .calendar .body .mobile-row.weekend {
  background-color: var(--holiday-color);
}

.shift-planner .calendar .body .mobile-row.faded {
	opacity: 0.5;
}

.shift-planner .calendar .body .cell .bg {
	font-weight: 700;
	line-height: 1;
	color: var(--main-color);
	opacity: 0;
	font-size: 5em;
	/*top: -.2em;*/
	right: -.05em;
	transition: .25s ease-out;
	letter-spacing: -.07em;
	position: relative;
	bottom: -10px;
}

.shift-planner .calendar .body .cell:hover .bg, .shift-planner .calendar .body .selected .bg  {
	opacity: 0.15;
	transition: .5s ease-in;
}

.shift-planner .calendar .body .col {
	flex-grow: 0;
	flex-basis: calc((100% - 80px)/7);
	width: calc((100% - 80px)/7);
	border-right: 0.5px solid #dfdfdf;
}

.shift-planner .calendar .week {
	flex-grow: 0;
	flex-basis: 80px;
	width: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.shift-planner .calendar .body .week {
	border-right: 1px solid black;
}

.shift-planner .calendar .col .ant-select, .shift-planner .calendar .mobile-row .ant-select {
	width: 100%;
	/*margin-top: 25px;*/
	border: none;
	background: #cfe5f9;
}

.shift-planner .calendar .bulk.editing .ant-select {
	width: 185px;
}

.shift-planner .calendar .col .selects {
	margin-top: 25px;
}

.shift-planner .calendar .col .button-clear {
	width: 100%;
	background-color: var(--remove-color);
	color: var(--neutral-color);
	text-transform: uppercase;
	letter-spacing: 2px;
	position: absolute;
	bottom: 0;
	text-align: center;
	font-size: 12px;
}

.shift-planner .calendar .body .col .ant-select-selector {
	border: none;
	background: #cfe5f9;
}

.shift-planner .calendar .body .col.holiday .ant-select-selector,
.shift-planner .calendar .body .col.weekend .ant-select-selector {
  background: var(--holiday-color-accent);
}

.shift-planner .calendar .body .col.faded .ant-select-selector {
	opacity: 0.5;
}

.shift-planner .calendar .body .mobile-row .ant-select-selector {
	border: none;
	background: #cfe5f9;
}

.shift-planner .calendar .body .mobile-row.holiday .ant-select-selector,
.shift-planner .calendar .body .mobile-row.weekend .ant-select-selector {
  background: var(--holiday-color-accent);
}

.shift-planner .calendar .body .mobile-row.faded .ant-select-selector {
	opacity: 0.5;
}

.shift-planner .calendar .body .disabled .ant-select-selector {
	border: none;
	background: #d6d6d6;
}

.shift-planner .calendar .body .bulk-name {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
}

.shift-planner .calendar .mask {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.shift-planner .calendar .mask .dialog {
	width: 40%;
	height: 70%;
	padding: 10px 25px;
	border-radius: 5px;
	background-color: #f3f3f3; 
}

@media only screen and (max-width: 700px) {
	.shift-planner .calendar .mask .dialog {
		width: 96%;
		height: 70%;
		padding: 10px 25px;
		border-radius: 5px;
		background-color: #f3f3f3; 
	}
}

.shift-planner .calendar .mask .decline svg {
	height: 16px;
	width: 16px;
}

.shift-planner .calendar .mask .decline svg:hover {
	cursor: pointer;
}

.shift-planner .calendar .mask  .hours-of-day {
	overflow-y: auto;
	height: calc(100% - 100px)
}

.shift-planner .calendar .mask .hours-of-day .hour {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.shift-planner .calendar .mask .hours-of-day .hour .number {
	flex: none;
	margin-right: 20px;
	min-width: 83px;
	text-align: end;
}

.shift-planner .calendar .mask .hours-of-day .hour .ant-select {
	flex: 1;
	margin-right: 10px;
}

.shift-planner .calendar .mask .dialog .hour-header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.shift-planner .calendar .mask .dialog .hour-header h1 {
	flex: 1 0;
}

.shift-planner .calendar .mask .dialog .hour-header .decline {
	flex: 1 0;
	text-align: end;
}

.shift-planner .calendar .shifts-ranges {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.shift-planner .calendar .shifts-ranges .label {
	min-width: 83px;
	margin-right: 20px;
}

.shift-planner .calendar .shifts-ranges .range {
	flex: 1;
	text-align: center;
}

.shift-planner .calendar .shifts-ranges .range.selected {
	background-color: #53cbf1;
}

.shift-planner .calendar .shifts-ranges .range:hover {
	cursor: pointer;
	background-color: #a2dbec;
}

.shift-planner .calendar .calendar-select {
	flex: 1 0;
	display: flex;
	flex-direction: column;
	flex: 1 0;
}

.shift-planner .calendar .select-label {
	height: 10px;
	background-color: #eca2a2;
	font-size: 8px;
	min-width: 250px;
	width: 250px;
}

.shift-planner .calendar .calendar-select .ant-select-selector  {
	width: 250px;
}

.shift-planner .calendar .offset {
	flex: 1 0;
}

.shift-planner .calendar .mid {
	flex: 1 0;
}