.page-header {
  font-size: 16px;
  width: 100%;
  color: white;
  background-color: #001529;
  /* display: flex; */
}

.page-header .button {
  background: #1a3455;
  float: right;
  padding: 2px 6px;
  margin-right: 6px;
}

.page-header .right .icon {
  float: left;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.page-header .user-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-header .button,
.page-header .ant-menu-submenu {
  background-color: #001529;
  color: rgba(255, 255, 255, 0.65) !important;
}

.page-header .button:hover,
.page-header .ant-menu-submenu:hover {
  background-color: #1890ff;
  color: rgba(255, 255, 255, 1) !important;
}