
.dialogOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.dialogFrame {
  margin: 10% auto;
  padding: 9px;
  border-radius: 9px;
  border: 1px solid #023368;
  background: white;
  width: 500px;
  overflow-y: visible;
}

.dialogFrame.size1 {
  width: 400px;
}

.dialogFrame.size2 {
  width: 450px;
}

.dialogFrame.size3 {
  width: 500px;
}

.dialogFrame.size4 {
  width: 1100px;
}

.dialogFrame .fullWidth {
  width: 100%;
  overflow: auto;
}

.dialogFrame .title {
  margin: 10px;
}

.dialogFrame .title label {
  font-size: 120%;
  font-weight: bold;
  color: #005aa0;
}

/* --------------------------------------------------- */

.dialogFrame .dialogControls {
  overflow:auto;
}

.dialogFrame .dialogControls .button {
  margin: 10px 5px 5px 0;
}

/* --------------------------------------------------- */

.dialogFrame .formRow {
  background: #fff;
  width: auto;
  margin-left: 30px;
}

.dialogFrame .formRow:nth-child(even) {
  background: #fff;
}

.dialogFrame .formRow label {
  width: 120px;
}

.dialogFrame .formRow input,
.dialogFrame .formRow select,
.dialogFrame .formRow textarea,
.dialogFrame input[type='select'],
.dialogFrame input[type='text'] {
  border: 1px solid #a6abb2;
  border-radius: 3px;
  padding: 3px;
  width: 300px;
}

.dialogFrame .buttonsToSelect {
  margin-left: 20px;
  display: flex;
}

.dialogFrame .buttonsToSelect div.button {
  background-color: #595f66;
  cursor: hand;
}

.dialogFrame .buttonsToSelect div.button:hover {
  background-color: #697078;
}

/* --------------------------------------------------- */

.dialogFrame ul.list li {
  margin: 0px;
  display: flex;
}

.dialogFrame .section div.button {
  float: right;
  padding: 0px;
  margin: 5px 0 0 3px;
  background: initial;
  color: black;
}

.dialogFrame .section div.button.left {
  float: left;
  margin-left: 20px;
}

.dialogFrame .section div.button div.icon {
  filter: invert(0%);
  -webkit-filter: invert(0%);
}

/* --------------------------------------------------- */

.dialogFrame label.time {
  font-weight: bold;
  margin-right: 5px;
}

/* --------------------------------------------------- */

.dialogFrame .formBlock {
  margin-left: 25px;
}

.dialogFrame .formBlock label {
  padding: 3px;
}

.dialogFrame .formBlock input[type="text"] {
  margin: 5px 0 0 0;
}

.dialogFrame .formBlock .ant-select {
  width: 400px;
}

/* --------------------------------------------------- */

.dialogFrame .link {
  color: #12538c;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.dialogFrame .link:hover {
  color: #1c80d7;
}

.dialogFrame .link.right {
  float: right;
}

.dialogFrame .link.left {
  float: left;
}

/* --------------------------------------------------- */

.dialogFrame .buttonsList {
  width: 100%;
  overflow: auto;
}

.dialogFrame .buttonsList .button {
  background-color: #bee2ff;
}

/* --------------------------------------------------- */

.dialogFrame .wrappedHorizontalList {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
}

.dialogFrame .wrappedHorizontalList .wrappedHorizontalListItem {
  background: #e4e4e4;
  padding: 10px;
  border-radius: 7px;
  margin: 0 10px 10px 0;
}

.dialogFrame .wrappedHorizontalList .wrappedHorizontalListItem.selected {
  background: #c2c2c2;
}

.dialogFrame .wrappedHorizontalList .wrappedHorizontalListItem:hover {
  cursor: pointer;
  background: #c2c2c2;
}

/* --------------------------------------------------- */

.dialogFrame .uploadEntityDialogHeader {
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialogFrame .uploadEntityDialogTitle {
  font-size: 24px;
  font-weight: bold;
}

/* --------------------------------------------------- */

.dialogFrame .flexSection {
  display: flex;
  margin: 10px;
  align-items: center;
}

.dialogFrame .flexSection > * {
  margin-right: 5px;
}