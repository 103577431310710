@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.shift-planner .month.icon {
  font-family: 'Material Icons', serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
  line-height: 25px;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

.shift-planner .left-mid-row {
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
  gap: 10px;

  justify-content: center;
  align-items: center;
  
  padding: 0px 18px;
  margin-bottom: 5px;
}

.shift-planner .left-mid-row.mobile {
  grid-template-columns: auto;
}

.shift-planner .left-mid-row .month.icon {
	cursor: pointer;
	transition: .15s ease-out;
}

.shift-planner .left-mid-row .month.icon:hover {
	transform: scale(1.75);
	transition: .25s ease-out;
	color: var(--main-color);
}

.shift-planner .left-mid-row .month.icon:first-of-type {
	margin-left: 1em;
}

.shift-planner .left-mid-row .month.icon:last-of-type {
	margin: 0;
}



/* VARIABLES */

:root {
  --main-color: #1a8fff;
  --dark-main-color: #0f4b83;
  --dark-main-color-accent: #125ca1;
  --text-color: #777;
  --disabled-color: #e4e4e4;
  --holiday-color: #fffdea;
  --holiday-color-accent: #faf2a6;
  /*
  --holiday-color: #d6fbe8;
  --holiday-color-accent: #a8f7ce;
  */
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
  --remove-color: #fc3131;
}


/* GENERAL */

* {
  box-sizing: border-box;
}

.calendar-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
}

/* .content .shift-planner {
  font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 2.5;
  color: var(--text-color);
  background: var(--bg-color);
  position: relative;
} */

.shift-planner {
  display: block;
  margin: 0 auto;
}

.shift-planner .left-mid-row {
  margin-top: 20px;
}

.shift-planner .left-mid-row .col-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.shift-planner .left-mid-row .col-center .ant-select {
	height: 32px;
	margin: 0px;
	width: auto;
	font-size: 25px;
	max-width: fit-content;
}

.shift-planner .left-mid-row .calendar-select .ant-select {
	width: 200px;
}

.shift-planner .left-mid-row .col-center .ant-select-selector {
	height: 32px;
	display: flex;
	align-items: center;
}

.shift-planner .left-mid-row .col-center .ant-input-number {
	border: none;
	font-size: 24px;
}


/* GRID */

.shift-planner .calendar .row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.shift-planner .row-middle {
  align-items: center;
}

.shift-planner .col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.shift-planner .col-start {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.shift-planner .col-center {
  justify-content: center;
  text-align: center;
}

.shift-planner .col-end {
  display: flex;
  align-items: center;
  justify-content: center;
}

.shift-planner .bulk-editing .ant-select {
	width: 185px;
	margin-right: 15px;
}

.shift-planner .bulk-editing {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 10px;
	height: 32px;
	margin-right: 10px;
  padding: 0px 18px;
}

.shift-planner.mobile .bulk-editing {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 0px;
  padding: 0px 18px;
  flex-direction: column;
  height: auto;
  margin-top: 0px;
}

.shift-planner.mobile .bulk-editing .ant-select {
  width: 100%;
  margin-top: 10px;
  margin-right: 0px;
}

.shift-planner.mobile .bulk-editing .ant-switch {
  margin-top: 10px;
}

.shift-planner .left-mid-row .row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  align-items: flex-end;
  padding: 0px 18px;
}

.shift-planner .left-mid-row .row.mobile {
  justify-content: center;
}

.shift-planner .left-mid-row .mid {
  display: flex;
  justify-self: center;
}

.shift-planner .left-mid-row.row .calendar-select .select-label {
  margin-right: 10px;
}

.shift-planner .mobile-calendar-select .select-label, .shift-planner .mobile-calendar-select .ant-select {
	width: 100%;
	text-align: center;
}
