.controls {
  position: absolute;
  right: 18px;
  top: 18px;
  padding: 6px;
  border-radius: 6px;
  background: #f2f2f2;
}

.controls.float {
  position: relative;
  float: right;
  padding: 6px;
  border-radius: 6px;
  background: #f2f2f2;
  top: 0px;
  margin-bottom: 5px;
}

.controls .title {
  float: left;
  margin-right: 6px;
  font-size: 24px;
  font-weight: bold;
}

.controls .title .icon {
  float: left;
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
}

.controls .title span {
  float: left;
  margin: 0 9px;
}

.controls .pages {
  float: left;
  margin: 9px 12px 0 9px;
  font-size: 16px;
  font-weight: bold;
}

.controls select {
  float: left;
  margin: 5px 9px 4px 6px;
  font-size: 16px;
  font-weight: bold;
}

.controls input {
  float: left;
  margin: 5px 9px 0 6px;
  font-size: 16px;
  font-weight: bold;
  width: 300px;
}

.controls input[type='checkbox'] {
  width: initial;
  margin: 3px;
}

.controls .block {
  float: left;
  margin: 7px;
  font-size: 16px;
  font-weight: bold;
}

/* --------------------------------------------------- */

.communicationControls {
  position: absolute;
  right: 9px;
  top: 9px;
  padding: 6px;
  margin: 0;
  font-size: 16px;
  display: flex;
}

.communicationControls .communicationButton {
  background: #C7DFF9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 3px 15px 3px 3px;
  max-width: 150px;
  height: 40px;
  margin-left: 10px;
  color: #32393f;
  text-transform: uppercase;
  font-weight: bold;
}

.communicationControls .communicationButton:hover {
  cursor: pointer;
  background: #a8cdf6;
}

.communicationControls .communicationButton .icon {
  margin-right: 4px;
  width: 30px;
  height: 30px;
  background-size: 30px;
}