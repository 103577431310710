
.icon {
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
}

.icon.small {
  background-size: 18px 18px;
}

.icon.half {
  background-size: 12px 12px;
}

.icon.big {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
}

.icon.withPointer {
  cursor: pointer;
}

.icon.semiTransparent {
  opacity: 0.5;
}

.icon.logout {
  background-image: url("../icons/icons8-shutdown-50.png");
}

.icon.error {
  background-image: url("../icons/icons8-error-50.png");
}

.icon.user {
  background-image: url("../icons/icons8-user-50.png");
}

.icon.search {
  background-image:url("../icons/icons8-search-more-50.png");
  margin: 0px;
}

.icon.add {
  background-image: url("../icons/icons8-plus-math-50.png");
}

.icon.revert {
  background-image: url("../icons/icons8-undo-50.png");
}

.icon.apply {
  background-image: url("../icons/icons8-save-50.png");
}

.icon.applyAndClose {
  background-image: url("../icons/icons8-save-close-50.png");
}

.icon.close {
  background-image: url("../icons/icons8-delete-50.png");
}

.icon.remove {
  background-image: url("../icons/icons8-remove-50.png");
}

.icon.role, .icon.global {
  background-image: url("../icons/icons8-category-50.png");
}

.icon.right {
  background-image: url("../icons/icons8-key-50.png");
}

.icon.authorization {
  background-image: url("../icons/icons8-privacy-50.png");
}

.icon.worker {
  background-image: url("../icons/icons8-technical-support-50.png");
}

.icon.time {
  background-image:url("../icons/icons8-clock-50.png");
}

.icon.sms {
  background-image:url("../icons/icons8-envelope-50.png");
}

.icon.device {
  background-image:url("../icons/icons8-sms-50.png");
}

.icon.port {
  background-image:url("../icons/icons8-ethernet-on-50.png");
}

.icon.calendar,
.icon.shiftplanner {
  background-image:url("../icons/icons8-calendar-50.png");
}

.icon.edit {
  background-image: url("../icons/icons8-pencil-50.png");
}

.icon.credit {
  background-image: url("../icons/icons8-money-50.png");
}

.icon.info {
  background-image: url("../icons/icons8-information-50.png");
}

.icon.tenant {
  background-image: url("../icons/icons8-module-50.png");
}

.icon.domain {
  background-image: url("../icons/icons8-globe-50.png");
}

.icon.language {
  background-image: url("../icons/icons8-geography-100.png");
}

.icon.language-en {
  background-image: url("../icons/iconfinder_16036.png");
}

.icon.language-cs {
  background-image: url("../icons/iconfinder_15845.png");
}

.icon.loading {
  background-image: url("../icons/icons8-hourglass-50.png");
  animation: loadRotation 2s infinite linear;
  -webkit-animation: loadRotation 2s infinite linear;
}

@keyframes loadRotation {
  0% { -webkit-transform: rotate(0deg); }
  25% { -webkit-transform: rotate(180deg); }
  50% { -webkit-transform: rotate(180deg); }
  75% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@-webkit-keyframes loadRotation {
  0% { -webkit-transform: rotate(0deg); }
  25% { -webkit-transform: rotate(180deg); }
  50% { -webkit-transform: rotate(180deg); }
  75% { -webkit-transform: rotate(360deg); }
  100% { -webkit-transform: rotate(360deg); }
}
