.loginScreen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginTitle {
  font-size: larger;
  position: relative;
  top: 14%;
  left: 0%;
  padding: 5px 50px;
  text-align: center;
  background: #CCFBFE;
  border: 3px solid #CDD6DD;
}

.loginForm {
  position: relative;
  padding: 20px 50px;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 100px;
  background: #CDD6DD;
  border: 3px solid #CDD6DD;
}

.loginForm label {
  font-size: 18px;
}

.loginForm input {
  padding: 7px;
  border-radius: 2px;
  width: 150px;
  display: block;
  margin: 10px auto;
  border: 0;
}

.loginForm .button:hover {
  cursor: pointer;
}