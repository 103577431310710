.stats .left-mid-row {
  display: grid;
  grid-template-columns: repeat(3, [col-start] 1fr);
  gap: 10px;

  justify-content: center;
  align-items: center;
  
  padding: 0px 18px;
  margin-bottom: 5px;
}

.stats .left-mid-row .ant-picker {
  width: 100%;
}

.stats .left-mid-row .line:last-of-type {
  margin-right: 20px
}

.stats .list .header .row {
  background-color: var(--dark-main-color);
  color: white;
  font-weight: 400;
}

.stats .headline .icon {
  margin-right: 9px;
  width: 28px;
  height: 28px;
  background-size: 28px 28px;
  background-color: #ffa55e;
  border-radius: 24px;
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

.stats .ant-picker-clear {
  display: none;
}

@media only screen and (max-width: 800px) {
  .stats .left-mid-row .ant-select, .stats .left-mid-row .calendar-name  {
    grid-column: 1 / span 3;
    width: 100%;
  }

  .stats .left-mid-row .calendar-name {
    padding: 0px 18px;
  }

  .stats .left-mid-row .ant-picker {
    grid-column: 1 / span 3;
    width: 100%;
    justify-self: center;
  }
}

/* @media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
} */

.responsive-list {
  padding: 0px 18px;
}

.responsive-list .header {
  background-color: var(--dark-main-color);
  color: white;
  font-weight: 400;
  padding: 5px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.responsive-list .header .header-item{
  flex: 1 0;
}

.responsive-list .responsive-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #a6d3f7;
  padding: 5px;
}

.responsive-list .responsive-row:nth-of-type(even) {
  background: #bee2ff;
}

.responsive-list .responsive-row .row-item {
  flex: 1 0;
}

.responsive-list .responsive-row .icon, .responsive-list .header .icon {
  flex: none;
  margin-right: 12px;
}

