.form-section {
  padding: 8px;
  overflow: auto;
}

.formSection {
  overflow: auto;
  padding: 8px 0;
}

.formSection:first-child {
  border-top: 0;
}

.formSection h1:hover {
  cursor: pointer;
}

.formSection h1 .editControls {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.formSection h1 .editControls > * {
  margin-right: 10px;
}

.formSection .content {
  margin: 0 18px 9px 18px;
  padding-bottom: 5px;
  overflow: auto;
  position: initial;
  border-width: 0;
}

/* --------------------------------------------------- */

ul.list.formList {
  width: 482px;
  margin-left: 18px;
}

/* --------------------------------------------------- */

.formRow {
  width: 500px;
  overflow: auto;
  background: #a6d3f7;
  margin-left: 18px;
  display: flex;
  align-items: center;
}

.formRow:nth-child(even) {
  background: #bee2ff;
}

.formRow.wrap {
  flex-wrap: wrap;
}

.formRow label {
  width: 310px;
  float: left;
  padding: 3px;
  padding-right: 5px;
  margin-top: 2px;
}

.formRow label:first-child {
  width: 174px;
  text-align: right;
}

.formRow input, .formRow select, .formRow textarea {
  width: 310px;
  float: left;
  font-size: 12px;
  padding: 3px;
  margin: 1px 0;
  background: rgba(255,255,255,0.3);
  border-radius: 3px;
  border: 1px solid #d9d199;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
}

.formRow input[type='checkbox'] {
  width: initial;
  margin-top: 3px;
}

.formRow audio {
  width: 310px;
}

.formRow ul.list li .row input {
  float: right;
  width: 200px;
  margin-top: 3px;
  margin-left: 5px;
  background: rgba(255,255,255,0.3);
  border-radius: 3px;
  border: 1px solid #d9d199;
  -ms-box-sizing:content-box;
  -moz-box-sizing:content-box;
  -webkit-box-sizing:content-box;
  box-sizing:content-box;
}

.formRow ul.list li .row input.withRightMargin {
  margin-right: 2px;
}

.formRow ul.list li > .row {
  width: 100%;
}

.formRow .button.initial {
  float: right;
  padding: 0px;
  margin-left: 3px;
  background: initial;
  color: black;
}

.formRow .button.initial.addButton {
  padding: 2px;
  margin: 3px 20px 5px 0px;
}

.formRow .button.initial.addButton:hover {
  background: #53d072;
}

.formRow .button.initial .icon {
  filter: none;
}

.formRow .button.right {
  margin-left: auto;
}

.formRow .ant-input-number-input {
  width: 125px;
  border: initial;
  background: initial;
  height: 24px;
  padding-left: 10px;
}

/* --------------------------------------------------- */

.doubleListContainer {
  display: table;
  width: 100%;
}

.doubleList {
  padding: 0;
  margin: 0;
  display: table-cell;
  width: 50%;
}

.doubleList .header {
  margin: 0 0 0 18px;
  padding: 3px 3px 0 3px;
  background: #a6d3f7;
  overflow: auto;
  border-radius: 9px 9px 0 0;
  width: max-content;
  line-height: 28px;
}

.doubleList ul {
  margin: 0 18px 0 18px;
  padding: 9px 9px 6px 9px;
  background: #a6d3f7;
  overflow: auto;
  list-style: none;
  border-radius: 0 9px 9px 9px;
}

.doubleList ul li {
  float: left;
  margin: 0 3px 3px 0;
}

.doubleList ul li .button {
  float: left;
  border-radius: 0;
  padding: 3px;
}

.doubleList ul li .button:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.doubleList ul li .button:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.hiddenList {
  display: none;
}

/* --------------------------------------------------- */

.doubleList .header > span {
  margin-right: 9px;
}

.doubleList .header .icon {
  float: left;
}

.doubleList .header select {
  margin-right: 6px;
  border: 1px solid #d9d199;
  font-size: 12px;
  padding: 3px;
  background: rgba(255,255,255,0.5);
  border-radius: 3px;
}

.doubleList .header input {
  margin-right: 6px;
  width: 200px;
  border: 1px solid #d9d199;
  font-size: 12px;
  padding: 3px;
  background: rgba(255,255,255,0.5);
  border-radius: 3px;
}

.doubleList .header .button {
  float: right;
  padding: 2px;
}

/* --------------------------------------------------- */

.infoBox {
  margin: 0 18px 9px 18px;
  padding : 9px;
  border-radius: 9px;
  /*background: #9d9d9c; */
  background: #e8e8e8;
  overflow: auto;
}

.infoBox .text {
  margin-top: 4px;
}

.infoBox .icon {
  float: left;
  margin-right: 9px;
}

/* --------------------------------------------------- */

textarea {
  resize: vertical;
  float: right;
  margin: 0 3px;
}

/* --------------------------------------------------- */

.formFrame {
  margin: 20px 0;
}


.formRowContainer {
  width: 550px;
  position: relative;
}

.formRowContainer .icon {
  position: absolute;
  right: 0;
  transform: translateY(-100%);
}

.formRowContainer .icon:hover {
  background-color: #53d072;
  border-radius: 5px;
}

.formRow > .button {
  margin: 3px;
}

/* --------------------------------------------------- */

ul.list.formList li.formListRow {
  background: #a6d3f7;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 600px;
}

ul.list.formList li.formListRow:nth-child(even) {
  background: #bee2ff;
}

.formListRow .button.initial.right {
  margin-left: auto;
}

.formListRow input {
  margin: 2px 5px;
}

.formListRow label {
  margin: 2px 2px 2px 10px;
}

.formListRow.withAddButton {
  background: #e0f1ff;
}

.formListRow.withAddButton .addButton {
  margin-left: auto;
}

/* --------------------------------------------------- */

.sublistTitle {
  font-weight: bold;
  font-style: italic;
}