
ul.list {
  padding: 0 18px;
  margin: 0;
}

ul.list ul.list {
  margin: 6px 18px 18px 32px;
  padding: 0;
}

ul.list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* --------------------------------------------------- */

ul.list li > .row {
  width: 100%;
  background: #a6d3f7;
  overflow: auto;
}

ul.list li:nth-child(even) > .row {
  background: #bee2ff;
}

ul.list li > .row.enabled {
  background: #78f096;
}

ul.list li:first-child {
  margin-top: 5px;
}

ul.list li:nth-child(even) > .row.enabled {
  background: #89ffa7;
}

ul.list li > .row.disabled {
  background: #fba47a;
}

ul.list li:nth-child(even) > .row.disabled {
  background: #ffb38e;
}

ul.list li > .row.grayed {
  background: #e6e6e6;
}

ul.list li:nth-child(even) > .row.grayed {
  background: #cecece;
}

ul.list li .row.withHover:hover {
  cursor: pointer;
  filter: drop-shadow(0 0 3px black);
}

/* --------------------------------------------------- */

ul.list li > .listHeader {
  background: #457a9c;
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

ul.list li.sticky {
  position: sticky;
  top: 0;
  z-index: 15;
  background: white;
}

ul.list li > .listHeader .icon {
  filter: invert(100%);
}

ul.list li > .listHeader .row {
  display: flex;
}

ul.list li > .listHeader .row > div {
  display: flex;
  justify-content: flex-start;
}

ul.list li > .listHeader .row > div > .button {
  display: flex;
  align-items: center;
  margin: 3px;
}

ul.list li > .listHeader .row > div > .button > .icon {
  margin: 0;
}

ul.list li > .listHeader .row > div.centered {
  justify-content: center;
}

ul.list .listHeader .row > div:first-child {
  border-top-left-radius: 10px;
}

ul.list .listHeader .row > div:hover {
  cursor: pointer;
  background-color: #3b6884;
}

ul.list .listHeader .row > .selected {
  padding: 2px 6px;
  line-height: 29px;
}

ul.list .listHeader .row .sort {
  float: right;
  margin-right: 5px;
}

ul.list .listHeader .row .sort.reversed {
  transform: rotate(180deg);
}

ul.list .listHeader .row .iconHeader {
  width: 30px;
}

ul.list .listHeader .row .headerDropZoneWrapper {
  width: 0px;
  height: 29px;
  position: relative;
  margin: 0;
  padding: 0;
}

ul.list .listHeader .row .headerDropZoneWrapper.dragStarted:before {
  content:'\20D2';
  color: black;
  font-size: 20px;
  position: absolute;
  transform: translate(-50%, 0);
}

ul.list .listHeader .row .headerDropZoneWrapper.dragStarted:after {
  content:'\25b3';
  color: black;
  font-size: 18px;
  position: absolute;
  transform: translate(-50%, 25px);
}

ul.list .listHeader .row .headerDropZoneWrapper.dragStarted.draggedOver:before {
  content:'\20D2';
  color: black;
  font-size: 25px;
  position: absolute;
  transform: translate(-50%, 0);
}

ul.list .listHeader .row .headerDropZoneWrapper.dragStarted.draggedOver:after {
  content:'\25b2';
  color: black;
  font-size: 18px;
  position: absolute;
  transform: translate(-50%, 25px);
}

ul.list .listHeader .row .headerDropZoneWrapper .headerDropZone {
  position: absolute;
  width: 40px;
  height: 50px;
  transform: translate(-50%, -10px);
  margin: 0;
  padding: 0;
}

ul.list .listHeader > .right {
  margin-left: auto;
  margin-right: 2px;
  position: relative;
}

ul.list .listHeader > .right .tableMenu {
  z-index: 10;
  background: white;
  position: absolute;
  right: 0;
  transform: translateY(29px);
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-left: 1px solid lightgray;
}

ul.list .listHeader > .right .tableMenu > .list {
  margin: 0;
}

ul.list .listHeader > .right .tableMenu > .list .tableMenuItem {
  width: 180px;
  padding: 5px 10px;
}

ul.list .listHeader > .right .tableMenu > .list .tableMenuItem:hover {
  background: #ededed;
}

ul.list .listHeader > div .button:hover {
  background: transparent;
}

/* --------------------------------------------------- */

ul.list.plain {
  flex-basis: 100%;
  height: max-content;
}

ul.list.plain li > .row {
  background: #fff;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

ul.list.plain li:nth-child(even) > .row {
  background: #eff4fa;
}

ul.list.plain li:first-child {
  margin-top: 0;
}

ul.list.plain li:last-child > .row {
  border-bottom: 1px solid #ddd;
}

ul.list.plain li > .row {
  display: flex;
}

ul.list.plain li > .row > .centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.list.plain.header {
  padding-right: 25px;
}

/* --------------------------------------------------- */

ul.list li .icon {
  margin: 2px;
  padding: 0;
  filter: none;
}

ul.list .row .icon {
  margin: 5px;
  padding: 0;
  filter: none;
}

ul.list .row .icon.large {
  margin: 4px;
}

ul.list li .icon.floatRight {
  float: right;
}

ul.list .row div {
  float: left;
  padding: 6px;
  margin-right: 3px;
}

ul.list .row div span {
  margin: 0 5px 0 5px;
}

ul.list .row div.noRightPadding {
  padding-right: 0;
  margin-right: 0;
}

ul.list .row div.noLeftPadding {
  padding-left: 0;
  margin-left: 0;
}

ul.list li div.button {
  float: right;
  padding: 0px;
  margin-left: 3px;
  background: initial;
  color: black;
}

ul.list li div.button:hover {
  background: #53d072;
}

ul.list li div.button.left {
  float:left;
  margin-left: 0;
  margin-right: 3px;
}

ul.list li div.button.right {
  margin-left: auto;
}

ul.list li div.button.left div.button.right:hover {
  background: #fba47a;
}

ul.list li div.button.left.marked div.button.right:hover {
  background: #53d072;
}

ul.list li div.button span {
  margin: 3px 6px 3px 0;
  float: left;
}

ul.list li div.button span.noRightMargin {
  margin-right: 0;
}

ul.list li div.button.disabled {
  background: initial;
  cursor: initial;
}

ul.list li div.button.marked {
  background: #fba47a;
}

ul.list .row div.floatRight {
  float: right;
}

ul.list .row .size1 {
  width: 40px;
}

ul.list .row .size2 {
  width: 60px;
}

ul.list .row .size3 {
  width: 120px;
}

ul.list .row .size4 {
  width: 180px;
}

ul.list .row .size5 {
  width: 240px;
}

ul.list .row .size6 {
  width: 300px;
}

/* --------------------------------------------------- */

ul.list .row input,
ul.list .row select {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid #fff;
  padding: 3px;
  margin: 1px;
  float: left;
}

/* --------------------------------------------------- */

ul.list .row div.draggable {
  float: right;
  padding: 0px;
  margin-left: 3px;
  background: #ece472;
  color: black;
  border-radius: 6px;
  margin-top: 5px;
  line-height: 30px;
}

ul.list .row div.draggable div {
  padding: 1px;
}

ul.list .row div.draggable.withHover:hover {
  background: #f0ea90;
  filter: drop-shadow(0 0 1px gray);
}

ul.list .row div.draggable.withHover div.drag:hover {
  cursor: move;
}

ul.list .row div.draggable label {
  margin-right: 5px;
}

ul.list .row div.draggable label:first-child {
  margin-left: 5px;
}

/* --------------------------------------------------- */

ul.list ul.list .row.withPadding {
  padding: 5px;
}

ul.list ul.list.shrinkMargin {
  margin-top: 1px;
  margin-bottom: 5px;
}

/* --------------------------------------------------- */

ul.list .row .withIcon {
  padding: 0 0 1px 0;
}

ul.list div.row .highlighted {
  padding: 3px;
  margin: 2px 2px 2px 35px;
}

ul.list .highlighted span {
  font-style: italic;
}

/* --------------------------------------------------- */

ul.list .row .communicationLink {
  color: #12538c;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

ul.list .row .communicationLink:hover {
  color: #1c80d7;
}

ul.list .row > .centeredVertically {
  display: flex;
  align-items: center;
}

ul.list .row > withCheckbox span {
  margin-left: 10px;
}

/* --------------------------------------------------- */

.tableControls {
  z-index: 100;
  position: absolute;
  right: 0;
  transform: translateY(calc(-100% - 5px));
  margin-right: 30px;
}

.tableControls .button {
  background: #457a9c;
}

.tableControls .button:hover {
  background: #518cb2;
}

/**************************************************************/

.ant-table .ant-table-thead .ant-table-cell {
  color: white;
  font-weight: bold;
  background-color: var(--dark-main-color);
}

.ant-table .ant-table-thead .ant-table-cell.ant-table-column-has-sorters:hover {
  background-color: var(--dark-main-color-accent);
}

.ant-table .table-row-light {
  background-color: #fff;
}

.ant-table .table-row-dark {
  background-color: #f3f3f3;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #daebfb !important;
}

.ant-table tr:nth-child(2n) td.ant-table-cell {
  background-color: #f3f3f3;
}

/**************************************************************/
.ant-table-row.workerTotal .ant-table-cell {
  font-weight: bold;
}

.ant-table-row.workerZero .ant-table-cell {
  color: rgb(173, 173, 173);
}